import {createStore} from 'vuex'
 
export default createStore({
  state: {
    isEn: true,

    zoomIn: false
  },
  getters: {
    
  },
  //里面定义方法，操作state方发
  mutations: {
    chgLang () {
      this.state.isEn = !this.state.isEn
    },
    zoomIn () {
      this.state.zoomIn = true
    },
    zoomOut () {
      this.state.zoomIn = false
    },
  },
  // 操作异步操作mutation
  actions: {
    
  },
  modules: {
    
  },
})

