<template>
    <transition name="fade">
        <section>
            <div class="loading">
                    <span>F</span>
                    <span>L</span>
                    <span>I</span>
                    <span>P</span>
            </div>
        </section>
    </transition>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '正在载入...'
    }
  }
}
</script>
<style lang="scss" scoped>
$r: 32;
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
section {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loading {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
}
.loading span{
    display: inline-block;
    width: 40/$r+rem;
    height: 40/$r+rem;
    -webkit-animation: load 1s ease infinite;
    margin-left: 5/$r+rem;
    font-weight: 900;
    text-align: center;
    line-height: 40/$r+rem;
    font-size: 40/$r+rem;
    transform-style: preserve-3d;
    perspective: 1000;
}
@-webkit-keyframes load{
    0%{
        color: rgba(0, 0, 0, 0.1);
        transform: rotateY(0deg);
    }
    25%{
        color: rgba(0, 0, 0, 0.3);
        transform: rotateY(-90deg);
    }
    50%{
        color: rgba(0, 0, 0, 0.5);
        transform: rotateY(-180deg);
    }
    75%{
        color: rgba(0, 0, 0, 0.7);
        transform: rotateY(-270deg);
        
    }
    100%{
        color: rgba(0, 0, 0, 0.9);
        transform: rotateY(-360deg);
    }
}
.loading span:nth-child(2){
    -webkit-animation-delay:0.2s;
}
.loading span:nth-child(3){
    -webkit-animation-delay:0.4s;
}
.loading span:nth-child(4){
    -webkit-animation-delay:0.6s;
}
.loading span:nth-child(5){
    -webkit-animation-delay:0.8s;
}
</style>

