import { createRouter, createWebHistory} from 'vue-router';

const Home = () => import('@/views/Home')
const Search = () => import('@/views/Search')
const Studio = () => import('@/views/Studio')
const Pop = () => import('@/views/Pop')
const PopDetail = () => import('@/views/PopDetail')
const About = () => import('@/views/About')
const Project = () => import('@/views/Project')
const ProjectDetail = () => import('@/views/ProjectDetail')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
  },
  {
    path: '/studio',
    name: 'Studio',
    component: Studio,
  },
  {
    path: '/pop',
    name: 'Pop',
    component: Pop,
  },
  {
    path: '/pop/detail',
    name: 'Ppdetail',
    component: PopDetail
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/project',
    name: 'Project',
    component: Project,
  },
  {
    path: '/project/detail',
    name: 'Pdetail',
    component: ProjectDetail
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router