import logo from './logo.vue';

const Logo = {
    install:function(Vue) {
        Vue.component('Logo', logo)
    }
}

export default Logo;

