<template>
  <div :id="elName"
       class="logo-word"></div>
</template>
<script>
import * as THREE from 'three'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls'
import * as dat from 'dat.gui'
// let moveMouse = ()=>{
//   console.log('12')
// }
export default {
  props: {
    elName: {
      type: String,
      default: ''
    },
    word: {
      type: String,
      default: ''
    },
    coordinate: {
      type: Object,
      default: () => ({})
    },
    position: {
      type: Object,
      default: () => ({})
    },
    imgIndex: {
      type: Number,
      default: -1,
    }
  },
  data () {
    return {
      addMaterial: null,
      removeMaterial: null,
      clearRenderer: null,
      mouseDown: false,
      nw: 2,
      nh: 1,
      p: 1,
      imgSrc: 'https://flip-studio-1312808535.cos.ap-shanghai.myqcloud.com/images/',
    }
  },
  methods: {
    createThree () {
      // 场景
      const scene = new THREE.Scene()
      // 相机
      let winWidth = window.innerWidth
      let winHeight = window.innerHeight
      // let winWidth = 1920;
      // let winHeight = 944;
      // let winWidth = 1440;
      // let winHeight = 764;
      if (winWidth < 1200) {
        winWidth = 1440;
      }
      let w = winWidth / this.nw;
      let h = winHeight / this.nh;
      this.p = winWidth/(1440/2)
      // const camera = new THREE.PerspectiveCamera(90,w/h, 1, 1000)
      const camera = new THREE.OrthographicCamera(-w / this.p, w / this.p, h / this.p, -h / this.p, 0.1, 3000)

      // camera.position.set(0, 0, 900)
      camera.position.set(this.position.x, this.position.y, this.position.z)
      
      // const gui = new dat.GUI()
      // gui.add(camera.position, "x").min(-999).max(999).step(1).name(this.word+'-cameraX')
      // gui.add(camera.position, "y").min(-999).max(999).step(1).name(this.word+'-cameraY')
      // gui.add(camera.position, "z").min(-999).max(999).step(1).name(this.word+'-cameraZ')
      // console.log(camera,'camera')
      // camera.lookAt(camera.position)
      // camera.lookAt(new THREE.Vector3(0, 0, 0))

      // 添加相机到场景中
      scene.add(camera)
      
      // 文字
      const loader = new FontLoader();
      let that = this;
      loader.load('fonts/helvetiker_bold.typeface.json',
        function (font) {
          const geometry = new TextGeometry(that.word, {
            font: font,
            size: 500,
            height: 100,
            curveSegments: 12,
            bevelEnabled: false,
            bevelSize: 8,
            bevelSegments: 5,
          });
          // 纹理
          const textureLoader = new THREE.TextureLoader();
          // const wordTexture = textureLoader.load('texture/006.jpg')
          // wordTexture.wrapS = THREE.RepeatWrapping
          // wordTexture.wrapT = THREE.RepeatWrapping;
          // wordTexture.offset.set( 0, 0.4 );
          // wordTexture.repeat.set( 0.001, 0.001 );
          // 材质
          // var meshMaterial = new THREE.MeshBasicMaterial({
          //   color: 0xffffff,
          //   map: wordTexture
          // });

          // var mesh = new THREE.Mesh(geometry, meshMaterial);
          // mesh.position.set(that.coordinate.x, that.coordinate.y, that.coordinate.z);
          // scene.add(mesh);
          // textureLoader.load('texture/home_00'+that.imgIndex+'.jpg',
          textureLoader.load(that.imgSrc+'home_00'+that.imgIndex+'.jpg',
            // onLoad回调
            function (texture) {
              texture.offset.set(0, 0.4);
              texture.repeat.set(0.001, 0.001);
              var meshMaterial = new THREE.MeshBasicMaterial({
                map: texture
              });

              var mesh = new THREE.Mesh(geometry, meshMaterial);
              // if (that.word == 'L' && innerHeight <= 680) {
              //   that.coordinate.y = -200;
              // }
              mesh.position.set(that.coordinate.x, that.coordinate.y, that.coordinate.z);
              if (that.word == 'F')
                mesh.rotation.set(0.09, .023, 0.09);
              if (that.word == 'L')
                mesh.rotation.set(0, 0, -0.18);
              if (that.word == 'I')
                mesh.rotation.set(0, 0, -0.18);
              if (that.word == 'P')
                mesh.rotation.set(-0.04, 0.09, 0)

              // const gui = new dat.GUI()
              // gui.add(mesh.rotation, "x").min(-Math.PI*2).max(Math.PI*2).step(0.01).name(that.word+'-rotationX')
              // gui.add(mesh.rotation, "y").min(-Math.PI*2).max(Math.PI*2).step(0.01).name(that.word+'-rotationY')
              // gui.add(mesh.rotation, "z").min(-Math.PI*2).max(Math.PI*2).step(0.01).name(that.word+'-rotationZ')
              // gui.add(mesh.position, "x").min(-999).max(999).step(1).name(that.word+'-positionX')
              // gui.add(mesh.position, "y").min(-999).max(999).step(1).name(that.word+'-positionY')
              // gui.add(mesh.position, "z").min(-999).max(999).step(1).name(that.word+'-positionZ')
              scene.add(mesh);
            },
          )

        },
        //加载进度
        function (xhr) {
          // console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
        },
        //出现错误
        function (err) {
          console.log(err);
        }
      );
      // 渲染器
      const renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true
      });
      // 渲染器透明
      renderer.setClearAlpha(0);
      // 渲染器尺寸
      renderer.setSize(w, h)
      document.getElementById(this.elName).appendChild(renderer.domElement)
      // 控制器
      // const controls = new OrbitControls(camera, renderer.domElement);
      // controls.enableDamping = true;  // 阻尼
      // controls.enablePan = false; // 平移
      // controls.enableZoom = false;  // 缩放
      // controls.autoRotate = true;
      const controls = new TrackballControls(camera, renderer.domElement)
      controls.noZoom = true;  //禁止缩放
      controls.noPan = true; //禁止平移
      controls.staticMoving = false; //阻尼是否被禁用
      controls.dynamicDampingFactor = 0.7;

      // controls.target = new THREE.Vector3(0, 0, -1200);

      function render () {
        controls.update()
        renderer.render(scene, camera)
        requestAnimationFrame(render)
      }
      render()

      // const axesHelper = new THREE.AxesHelper(50000)
      // scene.add(axesHelper)

      window.addEventListener('resize', () => {
        // if (window.innerWidth >= 1200) {
        //   this.nw = 2;
        //   this.nh = 1;
        // } else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
        //   this.nw = 2;
        //   this.nh = 1.5;
        // } else if (window.innerWidth < 992) {
        //   this.nw = 2;
        //   this.nh = 1;
        // }
        // let winWidth = window.innerWidth;
        // let winHeight = window.innerHeight;
        // // 1. 更新摄像头的宽高比
        // camera.aspect = winWidth / winHeight;
        // // 2. 更新摄像机的投影矩阵
        // camera.updateProjectionMatrix();

        // // 3. 更新渲染器
        // renderer.setSize(winWidth / this.nw, winHeight / this.nh);
        // // 4. 设置渲染器的像素比
        // renderer.setPixelRatio(window.devicePixelRatio);
      })
      /**
       * 更新材质
       */
      // this.addMaterial = () => {
      //   const textureLoader = new THREE.TextureLoader();
      //   const wordTexture = textureLoader.load('texture/006.jpg')
      //   wordTexture.wrapS = THREE.RepeatWrapping
      //   wordTexture.wrapT = THREE.RepeatWrapping;
      //   wordTexture.offset.set( 0, 0.5 );
      //   wordTexture.repeat.set( 0.001, 0.001 );

      //   // 材质
      //   // var meshMaterial = new THREE.MeshMatcapMaterial({
      //   var meshMaterial = new THREE.MeshBasicMaterial({
      //     color: 0xffffff,
      //     map: wordTexture
      //   });
      //   scene.overrideMaterial = meshMaterial
      // }
      // this.removeMaterial = () => {
      //   // const textureLoader = new THREE.TextureLoader();
      // //   const wordTexture = textureLoader.load('texture/castles.jpg')
      //   // 材质
      //   var meshMaterial = new THREE.MeshMatcapMaterial({
      //   // var meshMaterial = new THREE.MeshBasicMaterial({
      //     color: 0xffffff,
      //     // map: textureLoader
      //   });
      //   scene.overrideMaterial = meshMaterial
      // }
      this.clearRenderer = () => {
        console.log('clearRenderer')
        renderer.forceContextLoss();
      }
    },
    addListener () {
      // let el = document.getElementById(this.elName)
      // el.addEventListener('mousemove', moveMouse, false)
      // el.addEventListener('mousedown', ()=>{
      //   this.mouseDown = true
      // }, false)
      // el.addEventListener('mouseup', ()=>{
      //   this.mouseDown = false
      // }, false)
      // el.addEventListener('mousemove', (e)=>{
      //   if (!this.mouseDown) {
      //     let x = (e.clientX / window.innerWidth) * 2 - 1;
      //     let y = (e.clientY / window.innerHeight) * 2 - 1;
      //     console.log(this.elName,x,y)
      //   }
      // }, false)
    }
  },
  mounted () {
    this.$nextTick(() => {
      // if (window.innerWidth >= 1200) {
      //   this.nw = 2;
      //   this.nh = 1;
      // } else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
      //   this.nw = 2;
      //   this.nh = 1;
      // } else if (window.innerWidth < 992) {
      //   this.nw = 2;
      //   this.nh = 2;
      // }
      this.createThree()
      this.addListener()
    })
  },
  unmounted () {
    this.clearRenderer()
    // let el = document.getElementById(this.elName)
    // el.removeEventListener('mousemove', moveMouse, false)
  }
}
</script>
<style>
</style>

