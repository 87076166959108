<template>
  <div id="flipBody" :class="this.$store.state.isEn?'lang-en':'lang-cn'">
    <loading v-if="isLoading"></loading>
    <router-view v-slot="{ Component,route  }">
        <transition name="fade" mode="out-in">
            <component :is="Component" :key="route.path"/>
        </transition>
    </router-view>
    <span class="font1"></span>
    <span class="font2"></span>
    <span class="font3"></span>
    <span class="font4"></span>
  </div>
</template>

<script>
import Home from 'views/Home.vue'

export default {
  name: 'App',
  components: {
    Home
  },
  data () {
    return {
      isLoading: false,
    }
  },
  mounted () {
    // 设备切换
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    console.log(flag,'null - 电脑')
    if (flag) {
      window.location.href = "https://m.flip-studio.com"
    }
    // 屏幕自适应
    function placeholderPic () {
      console.log(document.documentElement.offsetWidth)
      //document.documentElement.offsetWidth  == 1920  这里暂时写死
      document.documentElement.style.fontSize=1920/60+"px"; //同上
    }
    placeholderPic ();
    // window.addEventListener('resize', () => {placeholderPic()}, false)
  }
}
</script>

<style lang="scss" scoped>
$r: 32;
</style>
