import logoword from './logoword.vue';

const Logoword = {
    install:function(Vue) {
        Vue.component('Logoword', logoword)
    }
}

export default Logoword;

