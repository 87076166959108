import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import loading from './components/common/loading/loading'
import logo from './components/common/logo/logo'
import logoword from './components/common/logoword/logoword'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import './assets/style/global.scss'
import './assets/style/flip.scss'
import './assets/style/reFlip.scss'
import './assets/style/flipStudio.scss'
import 'assets/style/font.css'

createApp(App).use(router).use(loading).use(logo).use(logoword).use(store).use(ElementPlus).mount('#app')
